/* eslint-disable object-shorthand */
import React, { useEffect, useState } from 'react';
import BuyerLandingHero from '../components/LandingPage/BuyerLandingHero';
import ShoppingStepsSection from '../components/LandingPage/ShoppingStepsSection.tsx';
import SellByTypesSection from '../components/LandingPage/SellByTypesSection.tsx';
import EnsureTrustSection from '../components/LandingPage/EnsureTrustSection';
import SellYourCarSection from '../components/LandingPage/SellYourCarSection';
import TredSection from '../components/LandingPage/TredSection.tsx';
import MyAreaSection from '../components/LandingPage/MyAreaSection';
import useDataLayer from '../analytics/useDataLayer';
import useDataIsland from '../analytics/useDataIsland';
import { useAnalytics } from 'reaxl-analytics';
import dynamic from 'next/dynamic';

const PopularVehiclesSection = dynamic(() => import('../components/LandingPage/PopularVehiclesSection'), { ssr: false });
export default function LandingPageContainer() {
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const dataIsland = useDataIsland();
  const dataLayer = useDataLayer();
  const { sendPage } = useAnalytics();
  const dataIslandParams = {
    page: 'syc_psx_buy',
    googlePageName: 'syc_psx_buy',
  };
  const onPageEnter = () => {
    sendPage({
      data: {
        ...dataIslandParams,
      },
      dataLayer,
      isInitialLoad: isInitialLoad,
      dataIsland,
      state: dataLayer?.state,
      userDuck: dataLayer?.userDuck,
    });
  };
  useEffect(() => {
    if (isInitialLoad) {
      onPageEnter();
      setIsInitialLoad(false);
    }
  }, []);
  return (
    <div>
      <BuyerLandingHero />
      <ShoppingStepsSection />
      <SellByTypesSection />
      <EnsureTrustSection />
      <PopularVehiclesSection />
      <SellYourCarSection />
      <TredSection />
      <MyAreaSection />
    </div>
  );
}
